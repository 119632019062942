<template>
  <div>
    <my-nav-bar
      title="未付明细"
      left-text="返回"
      left-arrow
      right-text="付款明细"
      @click-right="toPaymentView"
    />
    <div class="fixed-container">
      <van-cell
        title="开始日期"
        :value="listQuery.begin_date"
        is-link
        center
        @click="handleChooseDate('begin')"
      />
      <van-cell
        title="结束日期"
        :value="listQuery.end_date"
        is-link
        center
        @click="handleChooseDate('end')"
      />
      <van-cell
        title="未付金额(含退货)"
        value-class="cell-value"
        :value="'￥' + totalUnpaidAmount"
        center
      />
      <van-cell
        title="退货金额"
        value-class="cell-value"
        :value="'￥' + totalReturnAmount"
        center
      />
    </div>
    <van-list
      ref="list"
      v-model="loading"
      class="list"
      :finished="finished"
      @load="getList"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-checkbox-group ref="checkboxGroup" v-model="checkIndexs" @change="handleCheckboxChange">
        <div
          v-for="(order, index) in list"
          :key="order.id"
          class="cell-container"
        >
          <div class="cell" @click="handleCellCheck(index)">
            <div v-if="!order.business_payment_id" class="cell-check">
              <van-checkbox :ref="'checkbox'" :name="index" />
            </div>
            <div class="cell-content">
              <van-cell title="客户" :value="order.client.name" />
              <van-cell title="门店" :value="order.store.name" />
              <van-cell title="制单人" :value="order.creator.name" />
              <van-cell
                :title="order.type === 1 ? '货款金额' : '退货金额'"
                :value="'￥' + order.cost_amount"
                value-class="cell-value"
              />
              <van-cell title="制单时间" :value="order.created_at" />
            </div>
          </div>
        </div>
      </van-checkbox-group>
    </van-list>
    <div v-if="list.length > 0" class="fixed-bottom-btn">
      <div>
        <van-cell title="全选" @click="handleCheckAll">
          <template #right-icon>
            <van-checkbox v-model="checkAll" />
          </template>
        </van-cell>
      </div>
      <van-button :disabled="checkIndexs.length === 0" block square @click="handlePayment">付款</van-button>
    </div>
    <my-dialog v-model="showPaymentDialog" title="确认付款" @confirm="handlePaymentConfirm">
      <van-cell title="未付总额" value-class="cell-value" :value="'￥' + chooseAmount" />
      <van-cell title="付款方式" value-class="pay-mode-value" center>
        <van-radio-group v-model="paymentData.pay_mode" direction="horizontal">
          <van-radio class="pay-radio" icon-size="16px" :name="1">现金</van-radio>
          <van-radio class="pay-radio" icon-size="16px" :name="2">微信</van-radio>
          <van-radio class="pay-radio" icon-size="16px" :name="3">转账</van-radio>
        </van-radio-group>
      </van-cell>
      <van-cell
        title="付款日期"
        :value="paymentData.pay_date"
        center
        is-link
        @click="handleChooseDate('payment')"
      />
    </my-dialog>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="pickerDate"
        type="date"
        title="选择年月日"
        @confirm="handleDateConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getBusinessUnpaidDetail, payForBusiness } from '@/api/business-unpaid'
import { ImagePreview } from 'vant'
import { toDateString } from '@/utils'
import MyDialog from '@/components/my-dialog'
// import dayjs from 'dayjs'
const payModeMaps = {
  1: '现金',
  2: '微信',
  3: '转账'
}
export default {
  components: { myNavBar, MyDialog },
  filters: {
    payModeFilter(payMode) {
      return payModeMaps[payMode]
    }
  },
  data() {
    return {
      list: [],
      listQuery: {
        page: 0,
        limit: 10,
        // 默认显示上一个月份
        begin_date: '', // dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
        end_date: '' // dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      },
      totalUnpaidAmount: 0,
      totalReturnAmount: 0,
      pickerDate: new Date(),
      loading: false,
      finished: false,
      total: 0,
      showEmpty: false,
      show: false,
      dateType: '',
      showPaymentDialog: false,
      paymentData: {},
      chooseAmount: 0,
      checkAll: false,
      checkIndexs: []
    }
  },
  created() {
    this.initPaymentData()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getBusinessUnpaidDetail(this.$route.query.business_id, this.listQuery).then(
        (res) => {
          this.list = this.list.concat(res.data.list)
          this.loading = false
          this.finished = res.data.list.length < this.listQuery.limit
          this.showEmpty = this.list.length === 0
          this.total = res.data.total
          this.totalAmount = res.data.total_amount
          this.totalUnpaidAmount = res.data.total_unpaid_amount
          this.totalReturnAmount = res.data.total_return_amount
        }
      )
    },
    previewImage(image) {
      ImagePreview([image])
    },
    reload() {
      this.list = []
      this.listQuery.page = 0
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.checkAll = false
      this.$refs.list.check()
      this.initPaymentData()
    },
    initPaymentData() {
      this.paymentData = {
        pay_mode: 1,
        pay_date: '',
        real_amount: '',
        orders: []
      }
    },
    handleChooseDate(type) {
      this.dateType = type
      this.show = true
    },
    handleDateConfirm(date) {
      date = toDateString(date)
      if (this.dateType === 'begin') {
        this.listQuery.begin_date = date
        this.reload()
      } else if (this.dateType === 'end') {
        this.listQuery.end_date = date
        this.reload()
      } else {
        this.paymentData.pay_date = date
      }
      this.show = false
    },
    handlePayment() {
      this.showPaymentDialog = true
      this.chooseAmount = 0
      this.paymentData.orders = []
      this.list.forEach((order, index) => {
        if (this.checkIndexs.findIndex(index2 => index === index2) !== -1) {
          this.chooseAmount = (Number(this.chooseAmount) + Number(order.cost_amount)).toFixed(2)
          this.paymentData.orders.push({ id: order.id, type: order.type })
        }
      })
    },
    handleCellCheck(index) {
      const checkbox = this.$refs['checkbox'] ? this.$refs['checkbox'][index] : null
      if (!checkbox) { return }
      checkbox.toggle()
    },
    handlePaymentConfirm() {
      if (!this.paymentData.pay_date) {
        this.fail('请选择付款日期')
        return
      }
      this.beginLoad()
      const data = Object.assign({}, this.paymentData)
      data.business_id = this.$route.query.business_id
      payForBusiness(data).then(res => {
        this.success(res.msg)
        this.reload()
      })
    },
    handleCheckboxChange() {
      if (this.list.length === 0) { return }
      if (this.checkIndexs.length !== this.list.length) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },
    handleCheckAll() {
      this.checkAll = !this.checkAll
      this.$refs.checkboxGroup.toggleAll(this.checkAll)
    },
    toPaymentView() {
      this.$router.push({
        path: '/business-unpaid-payment',
        query: {
          business_id: this.$route.query.business_id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color.scss";
.cell-container {
  background-color: #fff;
  margin-bottom: 10px;
}
.cell {
  display: flex;
  align-items: center;
}
.cell-check .van-checkbox {
  padding: 10px;
}
.cell-content {
  width: 100%;
}
.fixed-container {
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  z-index: 9;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  color: #323232;
}
.cell-value {
  color: $amountRedColor;
}
.list {
  padding-top: 232px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 90px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 90px);
}
.footer {
  font-size: 12px;
  color: $grayTextColor;
}
.receipt {
  display: flex;
  margin-top: 5px;
  width: 100%;
}
.btns {
  display: flex;
  .van-button {
    flex: 1;
  }
}
.pay-mode-value {
  flex: 2;
}
.pay-radio {
  font-size: 13px;
}
.fixed-bottom-btn {
    height: 90px;
  }
</style>
